<template>
  <v-app>
    <v-stepper v-model="stepCount">
      <v-stepper-header>
        <v-stepper-step
          :complete="stepCount > getStepVal('emailSuppressionFileUpload')"
          :step="getStepVal('emailSuppressionFileUpload')"
        >
          Upload Email Suppression
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepCount > getStepVal('importEmailSuppression')"
          :step="getStepVal('importEmailSuppression')"
        >
          Import Email Suppression
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :complete="stepCount > getStepVal('emailSuppressionSummary')"
          :step="getStepVal('emailSuppressionSummary')"
        >
          Email Suppression Import Summary
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('emailSuppressionFileUpload')"
        >
          <EmailSuppressionFileUpload
            v-if="getStepVal('emailSuppressionFileUpload') == stepCount"
            ref="emailSuppressionFileUpload"
            :key="getStepKey('emailSuppressionFileUpload')"
            :step-val="getStepVal('emailSuppressionFileUpload')"
            :suppression-code="$route.query.sc"
            :step-count="stepCount"
            @row0="getRow"
            @fileArray="getFileArray"
            @fileData="getFileData"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('importEmailSuppression')"
        >
          <ImportEmailSuppression
            v-if="getStepVal('importEmailSuppression') == stepCount"
            ref="importEmailSuppression"
            :key="getStepKey('importEmailSuppression')"
            :step-val="getStepVal('importEmailSuppression')"
            :row0="row0"
            :file-array="fileArray"
            :file-data="fileData"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
        <v-stepper-content
          class="pa-3"
          :step="getStepVal('emailSuppressionSummary')"
        >
          <EmailSuppressionSummary
            v-if="getStepVal('emailSuppressionSummary') == stepCount"
            ref="emailSuppressionSummary"
            :key="getStepKey('emailSuppressionSummary')"
            :step-val="getStepVal('emailSuppressionSummary')"
            @cancelStep="cancelStep"
            @advanceFromStep="advanceFromStep"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmailSuppressionImport',

  components: {
    EmailSuppressionFileUpload: () => import('@/views/pages/suppression/EmailSuppressionFileUpload'),
    ImportEmailSuppression: () => import('@/views/pages/suppression/ImportEmailSuppression'),
    EmailSuppressionSummary: () => import('@/views/pages/suppression/EmailSuppressionSummary')
  },
  data () {
    return {
      stepCount: 1,
      routes: [
        {
          path: 'emailSuppressionFileUpload',
          ref: 'emailSuppressionFileUpload',
          stepVal: 1,
          key: 0
        },
        {
          path: 'importEmailSuppression',
          ref: 'importEmailSuppression',
          stepVal: 2,
          key: 0
        },
        {
          path: 'emailSuppressionSummary',
          ref: 'emailSuppressionSummary',
          stepVal: 3,
          key: 0
        }
      ],
      valid: false,
      isSingleColumn: false,
      fileData: '',
      row: '',
      row0: '',
      fileArray: [],
      resultsLoading: false,
      uploadingFiles: false,
      uploadError: ''
    }
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    },
    isLoading () {
      return this.loadingCount > 0
    }
  },

  methods: {
    clearErrors () {
      this.uploadError = ''
    },
    getStepVal (stepRef) {
      return this.routes.find(x => x.ref === stepRef).stepVal
    },
    getStepKey (stepRef) {
      return this.routes.find(x => x.ref === stepRef).key
    },
    async getRow (row0) {
      this.row0 = row0
    },
    async getFileArray (fileArray) {
      this.fileArray = fileArray
    },
    async getFileData (fileData) {
      this.fileData = fileData
    },
    async cancelStep (stepNum) {
      this.stepCount = stepNum - 1
    },
    async advanceFromStep (stepNum) {
      this.stepCount = stepNum + 1
    }
  }
}
</script>
